import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from 'gatsby-image';

class FeaturedPosts extends Component {
  render() {
    return (
      <>
        <div className="featured-posts">
          <div className="row columns-new">
            {
              this.props.data.allContentfulPost.edges.slice(0,1).map(({node:post, index}) => {
                return (
                  <div className={"col-12 columns"}>
                    <div className="post-item">
                      <div className="overlay"></div>
                      <Link to={`/${post.slug}`} className="link"></Link>
                      <Img className="image" fluid={post.realFeaturedImage.localFile.childImageSharp.fluid} />
                      <div className="title">{post.postTitle}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="row columns-new">
            {
              this.props.data.allContentfulPost.edges.slice(1,3).map(({node:post, index}) => {
                return (
                  <div className={"col-sm-12 col-md-6 col-lg-6 col-xl-6 columns"}>
                    <div className="post-item">
                      <div className="overlay"></div>
                      <Link to={`/${post.slug}`} className="link"></Link>
                      <Img className="image" fluid={post.realFeaturedImage.localFile.childImageSharp.fluid} />
                      <div className="title">{post.postTitle}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql` {
    allContentfulPost(filter: {categoryList: {elemMatch: {categorySlug:{eq: "featured" }}}}, sort: {order: DESC, fields: publishDate}, limit: 3) {
      edges {
        node {
          postTitle
          postAuthor {
            authorName
          }
          publishDate(formatString: "DD/MM/YYYY")
          realFeaturedImage {
            localFile {
              childImageSharp {
                    fluid(webpQuality:100, quality: 100)  {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
          }
          slug
        }
      }
  }
  }
`}
    render={(data) => (
      <FeaturedPosts data={data} />
    )}
  />
)
