import React from "react"
import Layout from "../components/layout"
import FeaturedPosts from "../components/FeaturedPosts/featuredposts"
import FeaturedReviews from "../components/FeaturedReviews/featuredReviews"
import LatestPosts from "../components/LatestPosts/latestPosts"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <>

    <Helmet>
      <html lang="pt-BR" amp />
      <meta charSet="UTF-8" />
      <title>AmazingTec | Movidos pela Incrível Tecnologia</title>
      <meta name="description" content="Fique por dentro das principais notícias de tecnologia, de forma fácil e simplificada. Mova-se pelo incrível!" />
      <meta name="robots" content="noindex,nofollow,max-snippet: -1,max-image-preview:large" />
      <link rel="canonical" href={"https://dev.amazingtec.com.br/"} />
      <link rel="next" href={"https://dev.amazingtec.com.br/"} />
      <meta property="og:locale" content="pt-BR" />
      <meta property="og:type" content="websute" />
      <meta property="og:title" content={"AmazingTec | Movidos pela Incrível Tecnologia"} />
      <meta property="og:description" content="Fique por dentro das principais notícias de tecnologia, de forma fácil e simplificada. Mova-se pelo incrível!" />
      <meta property="og:url" content={"https://dev.amazingtec.com.br/"} />
      <meta property="og:sitename" content="AmazingTec" />
      <meta property="og:image" content="" />
    </Helmet>
    <Layout>
      <FeaturedPosts />
      <FeaturedReviews />
      <LatestPosts />
    </Layout>

  </>
)

export default IndexPage
