import React, { Component } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from 'gatsby-image';

class FeaturedReviews extends Component {
    render() {
        const params = {
            slidesPerView: 1,
            navigation: {
                el: ".swiper-navigation",
            },
            pagination: {
                el: ".swiper-pagination.featured-review-pagination",
                clickable: true,
            },
        }
        return (
            <>
                <div className="featured-review">
                    <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                            {
                                this.props.data.allContentfulPost.edges.map(({node: post, index}) => {
                                    return (
                                      <div>
                                          <div className={"col-md-12"}>
                                              <div className="post-item">
                                                  <div className="overlay"></div>
                                                  <Link to={`/${post.slug}`} className="link"></Link>
                                                  <Img className="image" fluid={post.realFeaturedImage.localFile.childImageSharp.fluid} />
                                                  <div className="title">{post.postTitle}</div>
                                              </div>
                                          </div>

                                      </div>
                                    )
                                })
                            }
                    </div>
                    <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <div className="container">
                            <div className="row">
                                <h3 className="amztc-post-title">
                                    Nossos reviews
                                </h3>
                            </div>
                        </div>
                        <div className="">
                            <div className="row">
                                {
                                    this.props.data.allContentfulPost.edges.map(({node: post, index}) => {
                                        return (
                                              <div className={"col"}>
                                                  <div className="post-item">
                                                      <div className="overlay"></div>
                                                      <Link to={`/${post.slug}`} className="link"></Link>
                                                      <Img className="image" fluid={post.realFeaturedImage.localFile.childImageSharp.fluid} />
                                                      <div className="title">{post.postTitle}</div>
                                                  </div>
                                              </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default () => (
    <StaticQuery
        query={graphql` {
 allContentfulPost(filter: {categoryList: {elemMatch: {categorySlug:{eq: "reviews" }}}}, sort: {order: DESC, fields: publishDate}, limit: 3) {
      edges {
        node {
          postTitle
          metaDescription {
            metaDescription
          }
          realFeaturedImage {
            localFile {
              childImageSharp {
                fluid(webpQuality:100, quality: 100)  {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          slug
        }
      }
  }
  }
`}
        render={(data) => (
            <FeaturedReviews data={data} />
        )}
    />
)
